import React, { useState, useEffect } from 'react';
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@mui/icons-material/Edit';

import TablePagination from '@mui/material/TablePagination';

import { axiosPost } from '@/services/axios';
import CreateEditAssignModal from './CreateEditAssignModal';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { api_url } from '../../../constant';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Toolbar from '@mui/material/Toolbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteModal from './DeleteModal';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadModal from './UploadModal';
import CreateEditModal from './CreateEditModal ';
import BulkEditModal from './BulkEditModal';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp.js';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AutoAwesome, WorkHistoryRounded } from '@mui/icons-material';
import BulkAiAutoPopulateModal from './BulkAiAutoPopulateModal.jsx';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .50)'
            : 'rgba(150, 150, 150, .13)',
    flexDirection: 'row-reverse',
    color: 'black',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    padding: '0!important',
}));

export const SourceTypeList = [
    {
        value: 'Book (Full)',
    },
    {
        value: 'Book (Chapter)',
    },
    {
        value: 'Chapter (Edited Volume)',
    },
    {
        value: 'Journal Article',
    },
    {
        value: 'Conference Paper',
    },
    {
        value: 'Other',
    },
];

const headCells = [
    {
        id: 'faculty',
        numeric: false,
        sortable: true,
        label: 'Faculty',
    },
    {
        id: 'program',
        numeric: false,
        sortable: true,
        label: 'Programme',
    },
    {
        id: 'subjects',
        numeric: false,
        sortable: true,
        label: 'Subject',
    },
    {
        id: 'theme',
        numeric: true,
        sortable: true,
        label: 'Theme',
    },
    {
        id: 'premise',
        numeric: true,
        sortable: true,
        label: 'Premise',
    },
    {
        id: 'quote',
        numeric: true,
        sortable: true,
        label: 'Quote',
    },
    {
        id: 'created_at',
        numeric: true,
        sortable: true,
        label: 'Created At',
    },
    // {
    //     id: 'updated_at',
    //     numeric: true,
    //     sortable: true,
    //     label: 'Updated At',
    // },
    // {
    //     id: 'sq_click',
    //     numeric: true,
    //     sortable: true,
    //     label: 'SQ Click',
    // },
    // {
    //     id: 'sr_click',
    //     numeric: true,
    //     sortable: true,
    //     label: 'SR Click',
    // },
];

const handleQueuedJobs = () => {
    const newTab = window.open('/#/admin/queued-jobs', '_blank');
    newTab.focus();
};

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow className="bg-gray-100 text-black dark:bg-gray-700 dark:text-white">
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {headCell.sortable ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                    orderBy === headCell.id ? order : 'asc'
                                }
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc'
                                            ? 'sorted descending'
                                            : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <div>{headCell.label}</div>
                        )}
                    </TableCell>
                ))}
                <TableCell padding="normal">Action</TableCell>
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const {
        numSelected,
        handleDelete,
        handleExport,
        handleAssign,
        handleBulkEdit,
        handleBulkAiAutoPopulate,
    } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                position: 'sticky',
                top: 80,
                zIndex: 1100,
                bgcolor: (theme) =>
                    alpha(theme.palette.background.default, 1.0),
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.background.default, 1.0),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                ></Typography>
            )}
            <div className="flex">
                {numSelected > 0 ? (
                    <>
                        <Tooltip title="AI Auto-Populate">
                            <IconButton
                                onClick={(e) => handleBulkAiAutoPopulate()}
                            >
                                <AutoAwesome />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Change Faculty">
                            <IconButton onClick={(e) => handleBulkEdit()}>
                                <HistoryEduIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Assign">
                            <IconButton onClick={(e) => handleAssign()}>
                                <AssignmentReturnIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={(e) => handleDelete()}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <Tooltip title="Show Queued Jobs">
                            <IconButton onClick={handleQueuedJobs}>
                                <WorkHistoryRounded />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download Unallocated Premises">
                            <IconButton onClick={(e) => handleExport()}>
                                <FileDownloadIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
            </div>
        </Toolbar>
    );
}

const UnallocatedPremisePage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState('');

    const [source, setSource] = useState();

    const [faculty, setFaculty] = useState(0);
    const [faculties, setFaculties] = useState([]);

    const [programme, setProgramme] = useState(0);
    const [programmes, setProgrammes] = useState([]);

    const [subject, setSubject] = useState(0);
    const [subjects, setSubjects] = useState([]);

    const [theme, setTheme] = useState(0);
    const [themes, setThemes] = useState([]);

    const [selected, setSelected] = React.useState([]);
    const [selectTotal, setSelectTotal] = React.useState(false);

    const [groups, setGroups] = useState([]);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');

    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [openEditAssignModal, setOpenEditAssignModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
    const [bulkAiAutoPopulate, setBulkAiAutoPopulate] = useState(false);
    const [editItem, setEditItem] = useState();
    const [expanded, setExpanded] = useState(null);

    const [fileName, setFileName] = useState('');
    const [premiseCount, setPremiseCount] = useState({
        programs: 0,
        subjects: 0,
        themes: 0,
    });

    useEffect(() => {
        getFaculties();
    }, []);

    useEffect(() => {
        setProgramme(0);
        setProgrammes([]);
        setSubject(0);
        setSubjects([]);
        setTheme(0);
        setThemes([]);

        getProgrammes();
    }, [faculty]);

    useEffect(() => {
        setSubject(0);
        setSubjects([]);
        setTheme(0);
        setThemes([]);

        getSubjets();
    }, [programme]);

    useEffect(() => {
        setTheme(0);
        setThemes([]);

        getThemes();
    }, [subject]);

    const getFaculties = async () => {
        const ret = await axiosPost('/admin/faculty/getList');
        if (ret.status == 200) {
            const faculties = ret.data.data;
            setFaculties(faculties);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getProgrammes = async () => {
        const params = {
            faculty_id: faculty,
        };
        const ret = await axiosPost('/admin/program/getList', params);
        if (ret.status == 200) {
            const programmes = ret.data.data;
            setProgrammes(programmes);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getSubjets = async () => {
        const params = {
            program_id: programme,
        };
        const ret = await axiosPost('/admin/subject/getList', params);
        if (ret.status == 200) {
            const subjects = ret.data.data;
            setSubjects(subjects);
        } else {
            toast.error(ret.data.message);
        }
    };

    const getThemes = async () => {
        const params = {
            subject_id: subject,
        };
        const ret = await axiosPost('/admin/theme/getList', params);
        if (ret.status == 200) {
            const themes = ret.data.data;
            setThemes(themes);
        } else {
            toast.error(ret.data.message);
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event, fileName) => {
        if (event.target.checked) {
            const newSelected = groups[fileName].map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const onSelectTotalClick = (e) => {
        const tmp = Object.entries(groups);
        const allID = [];
        if(tmp) {
            tmp.forEach(element => {
                const arr = element[1];
                arr.forEach(item => {
                    allID.push(item.id);
                });
            });
        }

        if(e.target.checked) {
            setSelectTotal(true);
            setSelected(allID);
        }else{
            setSelectTotal(false);
            setSelected([]);
        }
    }

    useEffect(() => {
        if(selected.length == total && total != 0) {
            setSelectTotal(true);
        }else{
            setSelectTotal(false);
        }
    }, [selected, total]);

    const isSelected = (id) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    useEffect(() => {
        // Clear previous timeout to avoid redundant API calls
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        // Set new timeout to trigger search after 500ms (adjust as needed)
        const timeout = setTimeout(() => {
            getData();
        }, 1000); // Adjust delay time (in milliseconds) as needed

        // Update searchTimeout state to store the timeout ID
        setSearchTimeout(timeout);

        // Cleanup function to clear timeout when component unmounts or searchKey changes
        return () => clearTimeout(timeout);
    }, [
        searchKey,
        page,
        rowsPerPage,
        orderBy,
        order,
        faculty,
        programme,
        subject,
        theme,
    ]);

    const getData = async () => {
        setLoading(true);
        try {
            const params = {
                searchKey,
                orderBy,
                order,
                faculty_id: faculty,
                program_id: programme,
                subject_id: subject,
                theme_id: theme,
                unallocated: true,
                groupBy: 'temporary_data.file_name',
            };
            const ret = await axiosPost('/admin/premise/getList', params);
            if (ret.status == 200) {
                setGroups(ret.data.data);
                setTotal(ret.data.total);
                setSelected([]);
                setSelectTotal(false);
            }
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };

    const onCreated = () => {
        toast.success('Create Premise Successfully!');
        getData();
    };

    const handleEdit = (item) => {
        setEditItem(item);
        setOpenEditModal(true);
    };

    const onAssignUpdated = () => {
        toast.success('Assign Source Successfully!');
        getData();
    };

    const onEditUpdated = () => {
        toast.success('Premise Updated Successfully!');
        getData();
    };

    const onBulkEditUpdated = () => {
        toast.success('Premises Updated Successfully!');
        getData();
        setSelected([]);
    };

    const onBulkAiAutoPopulateUpdated = () => {
        toast.success('The AI Auto-Populate will work in the background!');
        setSelected([]);
    };

    const openExport = () => {
        let url = api_url + `/admin/premise/export-unallocated`;
        window.open(url, '_blank');
    };

    const handleDelete = async () => {
        if (selected.length > 0) {
            setOpenDeleteModal(true);
        }
    };

    const handleAssign = async () => {
        if (selected.length > 0 && expanded) {
            const selectedRows = groups[expanded].filter((row) =>
                selected.includes(row.id)
            );

            const program_ids = [];
            const subject_ids =[];
            const theme_ids =[];

            for (let index2 = 0; index2 < selectedRows.length; index2++) {
                const premise = selectedRows[index2];
                const theme_id = premise.theme_id;
                if( theme_id && !theme_ids.includes(theme_id)) {
                    theme_ids.push(theme_id);
                }
                const subject_id = premise.subject_id;
                if( subject_id && !subject_ids.includes(subject_id)) {
                    subject_ids.push(subject_id);
                }
                const program_id = premise.program_id;
                if( program_id && !program_ids.includes(program_id)) {
                    program_ids.push(program_id);
                }
            }

            setFileName(expanded);
            setPremiseCount({
                programs: program_ids.length,
                subjects: subject_ids.length,
                themes: theme_ids.length,
            });

            setOpenEditAssignModal(true);
        }
        if(selectTotal && total > 0) {
            const tmp = Object.entries(groups);
            const allID = [];

            const program_ids = [];
            const subject_ids =[];
            const theme_ids =[];

            if(tmp) {
                for (let index = 0; index < tmp.length; index++) {
                    const element = tmp[index];
                    const selectedRows = element[1];
                    
                    for (let index2 = 0; index2 < selectedRows.length; index2++) {
                        const premise = selectedRows[index2];
                        const theme_id = premise.theme_id;
                        if( theme_id && !theme_ids.includes(theme_id)) {
                            theme_ids.push(theme_id);
                        }
                        const subject_id = premise.subject_id;
                        if( subject_id && !subject_ids.includes(subject_id)) {
                            subject_ids.push(subject_id);
                        }
                        const program_id = premise.program_id;
                        if( program_id && !program_ids.includes(program_id)) {
                            program_ids.push(program_id);
                        }
                    }
                }
            }
            setFileName('');
            setPremiseCount({
                programs: program_ids.length,
                subjects: subject_ids.length,
                themes: theme_ids.length,
            });

            setOpenEditAssignModal(true);
        }
    };

    const handleBulkEdit = async () => {
        if (selected.length > 0) {
            setFileName(expanded);
            setOpenBulkEditModal(true);
        }
    };

    const handleBulkAiAutoPopulate = async () => {
        if (selected.length > 0) {
            setFileName(expanded);
            setBulkAiAutoPopulate(true);
        }
    };

    const handleDuplicate = async (id) => {
        const ret = await axiosPost('/admin/premise/duplicate', {
            id: id,
        });

        if (ret.status == 200) {
            toast.success(ret.data.message);
            getData();
        } else {
            toast.error(ret.data.message);
        }
    };

    const nextItem = async (old_id) => {
        let rows = groups[expanded];
        let index = rows.findIndex((row) => row.id === old_id);
        setOpenEditModal(false);
        if (index + 1 >= rows.length) {
            return;
        }
        let next_id = rows[index + 1].id;
        await getData();
        setOpenEditModal(true);
        setEditItem(next_id);
    };

    const prioritizeMissingValues = (rows) => {
        return rows.sort((a, b) => {
            const aMissingCount = ['program', 'subjects', 'theme'].filter(
                (key) => !a[key]
            ).length;
            const bMissingCount = ['program', 'subjects', 'theme'].filter(
                (key) => !b[key]
            ).length;

            return bMissingCount - aMissingCount;
        });
    };

    return (
        <div className="p-2">
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="relative mx-auto w-full p-4 dark:bg-transparent">
                <div className="flex justify-between">
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        {' '}
                        Unallocated Premises
                    </Typography>
                    <div className="flex">
                        <FormControlLabel control={<Checkbox checked={selectTotal} onClick={onSelectTotalClick} />} label="Select All" />
                        <Button
                            variant="contained"
                            color="warning"
                            sx={{ mr: 2 }}
                            style={{
                                color: 'white',
                            }}
                            onClick={(e) => setOpenUploadModal(true)}
                        >
                            <FileUploadIcon /> Import Premise
                        </Button>
                    </div>
                </div>
                <Typography sx={{ mb: 2 }} className="w-3/5">
                    {' '}
                    To pass validation rules, the XLS file should have the
                    following columns in order: Faculty, Programme, Subject,
                    Theme, Premise, Quote, Quote Page Range, Premise Type . Null
                    values must be filled before a premise can be allocated to a
                    source.
                </Typography>
                <Box
                    component="fieldset"
                    className="hidden rounded-md border border-slate-500 p-3 py-5"
                >
                    <legend className="ml-3 flex">
                        <div className="w-5"></div>
                        {' Filter '} <div className="w-5"></div>
                    </legend>
                    <Grid container spacing={1}>
                        <Grid item sm={2}>
                            <TextField
                                fullWidth
                                className="w-full"
                                id="outlined-error-helper-text"
                                label="Name"
                                value={searchKey}
                                size="small"
                                onChange={(e) => setSearchKey(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">
                                    Faculty
                                </InputLabel>
                                <Select
                                    className="w-full"
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={faculty}
                                    label="Faculty"
                                    onChange={(e) => setFaculty(e.target.value)}
                                >
                                    <MenuItem key={Math.random()} value={0}>
                                        All
                                    </MenuItem>
                                    {faculties.map((item) => (
                                        <MenuItem
                                            key={Math.random()}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">
                                    Programme
                                </InputLabel>
                                <Select
                                    className="w-full"
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={programme}
                                    label="Programme"
                                    onChange={(e) =>
                                        setProgramme(e.target.value)
                                    }
                                >
                                    <MenuItem key={Math.random()} value={0}>
                                        All
                                    </MenuItem>
                                    {programmes.map((item) => (
                                        <MenuItem
                                            key={Math.random()}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item sm={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">
                                    Subject
                                </InputLabel>
                                <Select
                                    className="w-full"
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={subject}
                                    label="Subject"
                                    onChange={(e) => setSubject(e.target.value)}
                                >
                                    <MenuItem key={Math.random()} value={0}>
                                        All
                                    </MenuItem>
                                    {subjects.map((item) => (
                                        <MenuItem
                                            key={Math.random()}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel id="demo-select-small-label">
                                    Theme
                                </InputLabel>
                                <Select
                                    className="w-full"
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={theme}
                                    label="Theme"
                                    onChange={(e) => setTheme(e.target.value)}
                                >
                                    <MenuItem key={Math.random()} value={0}>
                                        All
                                    </MenuItem>
                                    {themes.map((item) => (
                                        <MenuItem
                                            key={Math.random()}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>

                <EnhancedTableToolbar
                    numSelected={selected.length}
                    handleExport={openExport}
                    handleDelete={handleDelete}
                    handleAssign={handleAssign}
                    handleBulkEdit={handleBulkEdit}
                    handleBulkAiAutoPopulate={handleBulkAiAutoPopulate}
                />
                {groups &&
                    Object.entries(groups).map(([fileName, rows]) => {
                        return (
                            <Accordion
                                onChange={(e, expanded) =>
                                    setExpanded(expanded ? fileName : null)
                                }
                                expanded={expanded === fileName}
                                key={`group-${fileName}`}
                            >
                                <AccordionSummary
                                    sx={{
                                        position: 'sticky',
                                        top: 140,
                                        zIndex: 1000,
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? 'rgba(0, 0, 0, 1.0)'
                                                : 'rgba(255, 255, 255, 1.0)',
                                        transition:
                                            'box-shadow 0.3s ease-in-out',
                                        '&.Mui-expanded': {
                                            boxShadow: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '0px 4px 10px rgba(0, 0, 0, 0.2)'
                                                    : '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        },
                                    }}
                                >
                                    <div className="m-2 border-b font-bold text-black dark:text-white">
                                        {fileName}
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                            className="rounded-lg bg-gray-50 text-black dark:bg-gray-800 dark:text-white"
                                        >
                                            <EnhancedTableHead
                                                order={order}
                                                orderBy={orderBy}
                                                onRequestSort={
                                                    handleRequestSort
                                                }
                                                rowCount={rows.length}
                                                numSelected={selected.length}
                                                onSelectAllClick={(e) =>
                                                    handleSelectAllClick(
                                                        e,
                                                        fileName
                                                    )
                                                }
                                            />
                                            <TableBody>
                                                {prioritizeMissingValues(
                                                    rows
                                                ).map((row, index) => {
                                                    const isItemSelected =
                                                        isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (
                                                        <TableRow
                                                            key={index}
                                                            hover
                                                            role="checkbox"
                                                            aria-checked={
                                                                isItemSelected
                                                            }
                                                            tabIndex={-1}
                                                            selected={
                                                                isItemSelected
                                                            }
                                                        >
                                                            <TableCell
                                                                padding="checkbox"
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleClick(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={
                                                                        isItemSelected
                                                                    }
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.faculty ??
                                                                    '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.program ??
                                                                    '-'}
                                                            </TableCell>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {row.subjects ??
                                                                    '-'}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.theme ??
                                                                    '-'}
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={(
                                                                    event
                                                                ) =>
                                                                    handleClick(
                                                                        event,
                                                                        row.id
                                                                    )
                                                                }
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {row.premise}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.quote}
                                                            </TableCell>
                                                            <TableCell>
                                                                {dayjs(
                                                                    row.created_at
                                                                ).format(
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                {openUploadModal && (
                    <UploadModal
                        openModal={openUploadModal}
                        setOpenModal={setOpenUploadModal}
                        setLoading={setLoading}
                        reloadTable={getData}
                    />
                )}
                {openEditAssignModal && (
                    <CreateEditAssignModal
                        openModal={openEditAssignModal}
                        setOpenModal={setOpenEditAssignModal}
                        refresh={onAssignUpdated}
                        id={editItem}
                        ids={selected}
                        title={'Update Premise'}
                        fileName={fileName}
                        premiseCount={premiseCount}
                        selectTotal={selectTotal}
                    />
                )}
                {openEditModal && (
                    <CreateEditModal
                        openModal={openEditModal}
                        setOpenModal={setOpenEditModal}
                        refresh={onEditUpdated}
                        id={editItem}
                        nextItemHandle={nextItem}
                    />
                )}
                {openDeleteModal && (
                    <DeleteModal
                        openModal={openDeleteModal}
                        setOpenModal={setOpenDeleteModal}
                        refresh={getData}
                        ids={selected}
                        selectTotal={selectTotal}
                    />
                )}
                {openBulkEditModal && (
                    <BulkEditModal
                        openModal={openBulkEditModal}
                        setOpenModal={setOpenBulkEditModal}
                        refresh={onBulkEditUpdated}
                        ids={selected}
                        selectTotal={selectTotal}
                    />
                )}
                {bulkAiAutoPopulate && (
                    <BulkAiAutoPopulateModal
                        openModal={bulkAiAutoPopulate}
                        setOpenModal={setBulkAiAutoPopulate}
                        refresh={onBulkAiAutoPopulateUpdated}
                        ids={selected}
                        fileName={fileName}
                        selectTotal={selectTotal}
                    />
                )}
            </div>
        </div>
    );
};

export default UnallocatedPremisePage;
