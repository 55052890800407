import React, { useEffect, useRef, useState } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default function FilterProgram({ setKeyword }) {
    const timer = useRef(0);
    const [searchKey, setSearchKey] = useState('');
    const location = useLocation();

    const search = (inputValue) => {
        clearTimeout(timer.current);
        const newValue = inputValue ?? '';
        setSearchKey(newValue);
        timer.current = setTimeout(function () {
            setKeyword(newValue);
        }, 500);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchKey = params.get('search');

        setSearchKey(searchKey);
        search(searchKey);
    }, []);
    return (
        <div className="mt-4 flex w-full justify-between md:w-[400px]">
            <div className="flex w-full gap-2">
                <button
                    onClick={() => window.history.back()}
                    className="mr-2 rounded border border-white p-1 text-white transition-colors hover:bg-indigo-400 hover:text-white"
                >
                    <ArrowLeftIcon className="h-6 w-6" />
                </button>
                <TextField
                    type="text"
                    size="small"
                    InputProps={{
                        className: 'rounded mr-2 w-full bg-white',
                        style: { color: 'black' },
                    }}
                    placeholder="Search for Themes, Premises, Key Words…"
                    className="mr-2 flex-grow"
                    onChange={(e) => search(e.target.value)}
                    value={searchKey}
                />
            </div>
        </div>
    );
}
