import { Grid } from '@mui/material';
import { CountUp } from 'countup.js';
import React, { useEffect, useRef } from 'react';
import {
    BookOpenIcon,
    DocumentTextIcon,
} from '@heroicons/react/24/outline/index.js';

export default function TopSection({
    countCategories,
    subject = null,
    showSource = false,
    theme = null
}) {
    const themeCountUpRef = useRef(null);
    const premiseCountUpRef = useRef(null);
    const sourceCountUpRef = useRef(null);

    let anim = {};

    useEffect(() => {
        initCountUp();
    }, [countCategories]);

    async function initCountUp() {
        const countUps = [
            {
                ref: themeCountUpRef,
                anim: 'theme',
            },
            {
                ref: premiseCountUpRef,
                anim: 'premise',
            },
        ];

        for (const index in countUps) {
            const countUp = countUps[index];

            const to = countCategories[`${countUp.anim}s_count`]
                ? countCategories[`${countUp.anim}s_count`]
                : 0;
            anim[countUp.anim] = new CountUp(countUp.ref.current, to);
            if (!anim[countUp.anim].error) {
                anim[countUp.anim].start();
            } else {
                console.error(anim[countUp.anim].error);
            }
        }

        if (showSource) {
            const _to = countCategories['source'];
            anim['source'] = new CountUp(sourceCountUpRef.current, _to);

            if (!anim['source'].error) {
                anim['source'].start();
            } else {
                console.error(anim['source'].error);
            }
        }
    }

    return (
        <>
            <div className="mb-4 md:flex">
                <div className="mr-4 flex-1">
                    <h3 className="text-xl font-bold text-white">
                        {subject?.program.faculty.name}
                    </h3>
                    {subject && (
                        <h2 className="mb-3 text-2xl text-white">
                            <span className="font-bold">{'Programme'}: </span>
                            {subject?.program.name}
                        </h2>
                    )}
                    {subject && (
                        <h1 className="text-4xl text-white">
                            <span className="font-bold">{'Subject'}: </span>
                            {subject?.name}
                        </h1>
                    )}
                    {theme && (
                        <h1 className="mt-3 text-4xl text-white">
                            <span className="font-bold">{'Theme'}: </span>
                            {theme?.name}
                        </h1>
                    )}
                </div>
                <div className="mt-3 flex flex-col md:items-end">
                    {subject?.description && (
                        <div className="mb-5 rounded border border-white p-2 text-white">
                            {subject?.description}
                        </div>
                    )}
                    <div className="rounded border border-white p-2 text-white">
                        <p className="mb-4 w-48 font-bold">Searching:</p>
                        {showSource && (
                            <>
                                <div className="flex">
                                    <span>
                                        <DocumentTextIcon className="mr-2 mt-0.5 h-5 w-5 flex-shrink-0" />
                                    </span>
                                    Sources:
                                    <span
                                        className="ml-2"
                                        ref={sourceCountUpRef}
                                    >
                                        0
                                    </span>
                                </div>
                            </>
                        )}
                        <span className="mr-2 inline-block h-4 w-4 border border-white bg-[#0010aa] align-middle"></span>{' '}
                        Themes:
                        <span className="ml-2" ref={themeCountUpRef}>
                            0
                        </span>
                        <br />
                        <span className="mr-2 inline-block h-4 w-4 border border-white bg-[#10aa00] align-middle"></span>{' '}
                        Premises:
                        <span className="ml-2" ref={premiseCountUpRef}>
                            0
                        </span>
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
}
