import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { axiosPost } from '@/services/axios';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

const schema = yup.object().shape({
    source_id: yup.object().json(),
});

const formatSelect = (data, label, value) => {
    if (!data) {
        return [];
    }

    const newData = [];

    data.map((datum) => {
        newData.push({
            value: datum[value],
            label: datum[label],
        });
    });

    return newData;
};

const CreateEditAssignModal = ({
    setOpenModal,
    openModal,
    refresh,
    id,
    title,
    ids,
    fileName,
    premiseCount,
    selectTotal
}) => {
    const timer = useRef(0);
    const {
        register,
        reset,
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            source_id: null,
        },

        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const handleCloseConfirm = () => {
        setOpenModal(false);
    };

    const filterSources = (sources, inputValue) => {
        return sources.map((source) => {
            const name = source.author_name.find(
                (authorName) =>
                    authorName.first.includes(inputValue) ||
                    authorName.last.includes(inputValue)
            );

            return {
                value: source.id,
                label: `${name.last}, ${name.first} (${source.year_of_publication}) | ${source.title}`,
            };
        });
    };

    const loadSourceOptions = (inputValue, callback) => {
        if (inputValue) {
            clearTimeout(timer.current);
            timer.current = setTimeout(async () => {
                const ret = await axiosPost('/admin/premise/search/sources', {
                    keyword: inputValue,
                });
                const data = ret.data;
                callback(filterSources(data.data, inputValue));
            }, 2000);
        }
    };

    const transform = (data) => {
        return {
            ...data,
            source_id: data.source_id?.value,
            selectTotal: selectTotal
        };
    };
    const onSubmit = async (_data) => {
        const data = transform(_data);
        let url = '/admin/premise/assign';
        data['ids'] = ids;
        const ret = await axiosPost(url, data);
        if (ret.status == 200) {
            refresh();
            setOpenModal(false);
        } else {
            toast.error(ret.data.message);
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openModal}
                onClose={handleCloseConfirm}
            >
                <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                    <DialogTitle>Allocate</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} className="h-[400px]">
                            <Grid item sm={12}>
                                {
                                    selectTotal ? (
                                        <Typography variant="h6" sx={{ mb: 2 }}>
                                            Selected All
                                        </Typography>
                                    ) : (
                                        <Typography variant="h6" sx={{ mb: 2 }}>
                                            File: {fileName}
                                        </Typography>
                                    )
                                }

                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Allocated Premises: {premiseCount.programs}{' '}
                                    Programs, {premiseCount.subjects} Subjects,{' '}
                                    {premiseCount.themes} Themes
                                </Typography>

                                <Controller
                                    name="source_id"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({
                                        field: { value, onChange },
                                    }) => (
                                        <div className="mb-9 w-full">
                                            <label>Source</label>

                                            <AsyncSelect
                                                id="source_id"
                                                className="mt-1 block w-full"
                                                value={value}
                                                onChange={onChange}
                                                loadOptions={loadSourceOptions}
                                                placeholder="Enter author name"
                                                styles={{
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        background:
                                                            'transparent',
                                                    }),
                                                }}
                                                classNames={{
                                                    singleValue: (state) =>
                                                        'text-black dark:text-white',
                                                    menuList: (state) =>
                                                        'text-black',
                                                    input: (state) =>
                                                        'text-black dark:text-white',
                                                }}
                                            />
                                            {errors.source_id && (
                                                <span className="text-rose-500">
                                                    {errors.source_id.message}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCloseConfirm();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type="submit">Submit</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateEditAssignModal;
